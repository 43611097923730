import React from "react";

// reactstrap components
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";
import FooterWhite from "components/Footers/FooterWhite.js";

function SearchWithSidebar() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("search-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("search-page");
    };
  });
  return (
    <>
      <DangerNavbar />
      <div className="wrapper">
        <div className="main">
          <div className="section section-white section-search">
          <h1 className="text-center"><b>Payor Side Search</b></h1>
          <hr width="50%" ></hr>
          <br></br>
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="6" xs="12">
                  <Form className="form-inline search-form" role="search">
                    <InputGroup className="no-border">
                      <span
                        className="input-group-addon addon-xtreme no-border"
                        id="basic-addon1"
                      >
                        <i className="fa fa-search" />
                      </span>
                      <Input
                        aria-describedby="basic-addon1"
                        className="input-xtreme no-border text-center"
                        placeholder="Type drug here..."
                        type="text"
                      />
                    </InputGroup>
                  </Form>
                  
                  <FormGroup check>
                  <Label check>
                  <Input defaultValue="" type="checkbox" />
                  Location <span className="form-check-sign" />
                  </Label>
                  </FormGroup>

                  <FormGroup check>
                  <Label check>
                  <Input defaultValue="" type="checkbox" />
                  Cost <span className="form-check-sign" />
                  </Label>
                  </FormGroup>

                  <Button><i class="fa fa-filter" aria-hidden="true"></i></Button>

                  <br /> <br />

                  <h6 className="text-muted">
                    Pharmacies supplying the drug you searched for -- 
                  </h6>
                  <ul className="list-unstyled follows">
                    <li>
                      <Row>
                        <Col md="2" xs="3">
                          <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={require("assets/img/pharma-rx-icon.png")}
                          />
                        </Col>
                        <Col className="description" md="6" xs="4">
                          <h5>
                            <a href="https://google.com" target={"_blank"}> Pharmacy A</a> - <b>$340</b> <br />
                            <small>
                              Local pharmacy in San Diego
                            </small>
                          </h5>
                        </Col>
                        <Col md="2" xs="2">
                          <Button
                            className="btn-just-icon btn-round btn-tooltip"
                            color="danger"
                            id="tooltip565993392"
                            outline
                          >
                            <i className="fa fa-cart-plus" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip565993392"
                          >
                            add supplier to cart
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col md="2" xs="3">
                          <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={require("assets/img/pharma-rx-icon.png")}
                          />
                        </Col>
                        <Col className="description" md="6" xs="4">
                          <h5>
                          <a href="https://google.com" target={"_blank"}> Pharmacy B</a> - <b>$400</b><br />
                            <small>
                              340B institution 
                            </small>
                          </h5>
                        </Col>
                        <Col md="2" xs="2">
                          <Button
                            className="btn-just-icon btn-round btn-tooltip"
                            color="danger"
                            id="tooltip385246717"
                            outline
                          >
                            <i className="fa fa-cart-plus" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip385246717"
                          >
                            add pharmacy to cart
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col md="2" xs="3">
                          <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={require("assets/img/pharma-rx-icon.png")}
                          />
                        </Col>
                        <Col className="description" md="6" xs="4">
                          <h5>
                          <a href="https://google.com" target={"_blank"}>Pharmacy C</a> - <b>$370</b><br />
                            <small>
                              Local pharmacy in Stanford, CA
                            </small>
                          </h5>
                        </Col>
                        <Col md="2" xs="2">
                          <Button
                            className="btn-just-icon btn-round btn-tooltip"
                            color="danger"
                            id="tooltip890195664"
                            outline
                          >
                            <i className="fa fa-cart-plus" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip890195664"
                          >
                            add pharmacy to cart
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col md="2" xs="3">
                          <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={require("assets/img/pharma-rx-icon.png")}
                          />
                        </Col>
                        <Col className="description" md="6" xs="4">
                          <h5>
                          <a href="https://google.com" target={"_blank"}>Pharmacy D</a> - <b>$390</b><br />
                            <small>
                              GPO (aggregates pharmacies)
                            </small>
                          </h5>
                        </Col>
                        <Col md="2" xs="2">
                          <Button
                            className="btn-just-icon btn-round btn-tooltip"
                            color="danger"
                            id="tooltip588441915"
                            outline
                          >
                            <i className="fa fa-cart-plus" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip588441915"
                          >
                            add pharmacy to cart
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                    </li>
                  </ul>
                  <div className="text-missing">
                    <h5 className="text-muted"></h5>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default SearchWithSidebar;
