import React from "react";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  UncontrolledTooltip
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";
import FooterWhite from "components/Footers/FooterWhite.js";

function Settings() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("settings-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("settings-page");
    };
  });
  return (
    <>
      <DangerNavbar />


      <div className="wrapper">
        <div className="profile-content section">
        <h1 className="text-center"><b>Pharmacy Side Drug Listing</b></h1>
          <hr width="50%" ></hr>
          <br></br>
          <Container>
            <Row>
              <div className="profile-picture">
                <div
                  className="fileinput fileinput-new"
                  data-provides="fileinput"
                >
                  <div className="fileinput-new img-no-padding">
                    <img
                      alt="..."
                      src={require("assets/img/pharma-rx-icon.png")}
                    />
                  </div>
                  <div className="fileinput-preview fileinput-exists img-no-padding" />
                  <div>
                    <Button
                      className="btn-file btn-round"
                      color="default"
                      outline
                    >
                      <span className="fileinput-new">Change Photo</span>
                      <span className="fileinput-exists">Change</span>
                      <input name="..." type="file" />
                    </Button>
                    <br />
                    <Button
                      className="btn-link fileinput-exists"
                      color="danger"
                      data-dismiss="fileinput"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-times" />
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <Form className="settings-form">
                  <Row>
                    <Col md="6" sm="6">
                      <FormGroup>
                        <label>Pharmacy Name</label>
                        <Input
                          className="border-input"
                          placeholder="eg. Pharmacy A"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="6">
                      <FormGroup>
                        <label>Pharmacy Email</label>
                        <Input
                          className="border-input"
                          placeholder="eg. name@domain.com"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <label>Location</label>
                    <Input
                      className="border-input"
                      placeholder="eg. Stanford, CA"
                      type="text"
                    />
                  </FormGroup>
                  <h4 className="title">
                <small>Drugs Supplied</small>
              </h4>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Name</th>
                    <th>Link to Specs</th>
                    <th className="text-right">Price</th>
                    <th className="text-right">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">1</td>
                    <td>Humira</td>
                    <td><a href="https://google.com" target="_blank">Specs</a></td>
                    <td className="text-right">$340</td>
                    <td className="td-actions text-right">
                      <Button
                        className="btn-link mr-1"
                        color="success"
                        data-toggle="tooltip"
                        id="tooltip278266693"
                        size="sm"
                        type="button"
                      >
                        <i className="fa fa-edit" />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="tooltip278266693"
                      >
                        Edit Drug Info
                      </UncontrolledTooltip>
                      <Button
                        className="btn-link"
                        color="danger"
                        data-toggle="tooltip"
                        id="tooltip16493734"
                        size="sm"
                        type="button"
                      >
                        <i className="fa fa-times" />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="tooltip16493734"
                      >
                        Remove Drug
                      </UncontrolledTooltip>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center">2</td>
                    <td>Methotrexate</td>
                    <td><a href="https://google.com" target="_blank">Specs</a></td>
                    <td className="text-right">$370</td>
                    <td className="td-actions text-right">
                      <Button
                        className="btn-link mr-1"
                        color="success"
                        data-toggle="tooltip"
                        id="tooltip278266693"
                        size="sm"
                        type="button"
                      >
                        <i className="fa fa-edit" />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="tooltip278266693"
                      >
                        Edit Drug Info
                      </UncontrolledTooltip>
                      <Button
                        className="btn-link"
                        color="danger"
                        data-toggle="tooltip"
                        id="tooltip16493734"
                        size="sm"
                        type="button"
                      >
                        <i className="fa fa-times" />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="tooltip16493734"
                      >
                        Remove Drug
                      </UncontrolledTooltip>
                    </td>
                  </tr>

                  <tr>
                  <td>
                  <Button
                        className="btn-link mr-1"
                        color="success"
                        data-toggle="tooltip"
                        id="tooltip278266694"
                        size="sm"
                        type="button"
                      >
                        <i className="fa fa-plus" />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="tooltip278266694"
                      >
                        Add Drug
                      </UncontrolledTooltip>
                  </td>
                  <td>Add more drugs...</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  </tr>
                </tbody>
              </Table>


                  <ul className="notifications">
                    <li className="notification-item">
                      Receive a notification if payor is interested in one of your drugs{" "}
                      <Switch onColor="info" offColor="info" />
                    </li>
                  </ul>
                  <div className="text-center">
                    <Button
                      className="btn-wd btn-round"
                      color="info"
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default Settings;
