import { useState, useEffect } from "react";
import Select from 'react-select';
import Switch from 'react-bootstrap-switch';

// reactstrap components
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    InputGroup,
    InputGroupText,
    UncontrolledTooltip
} from "reactstrap";

// core components
import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import FooterWhite from "components/Footers/FooterWhite.js";

class Patient {
    #id = -1;
    #name = "";
    #testResults = {};
    constructor(id = -1, name = "", testResults = {}) {
        this.#id = id;
        this.#name = name;
        this.#testResults = testResults;
    }
    getId() {
        return this.#id;
    }
    getName() {
        return this.#name;
    }
    getTestResults() {
        return this.#testResults;
    }
    addTestResult(test, result) {
        this.#testResults[test] = result;
    }
    toString() {
        var testString = "";
        for (var key in this.#testResults) {
            testString += key + ": " + this.#testResults[key] + "\n";
        }
        return "Patient Name: " + this.#name + "\nPatient ID: " + this.#id + "\n\n" + testString;
    }

}

function Homepage() {

    const [inputColSize, setInputColSize] = useState(12);
    const [mobileOptimizedColSize, setMobileOptimizedColSize] = useState(4);

    const [hTabs1, setHTabs1] = useState("1");
    document.documentElement.classList.remove("nav-open");
    useEffect(() => {
        document.body.classList.add("presentation-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("presentation-page");
        };
    }, []);

    const patientOptions = [
        { value: "", label: "Select", isDisabled: true },
        { value: "John Doe", label: "John Doe" },
        { value: "Jane Stanford", label: "Jane Stanford" },
        { value: "Billy Bob", label: "Billy Bob" },
    ];

    const allPatients = [new Patient(1234567890, "John Doe"), new Patient(4567890123, "Jane Stanford"), new Patient(8901234567, "Billy Bob")];
    allPatients[0].addTestResult("TB", "Positive");
    allPatients[0].addTestResult("Pregnancy", "Negative");
    allPatients[0].addTestResult("Liver Function", "N/A");
    allPatients[1].addTestResult("TB", "Negative");
    allPatients[1].addTestResult("Pregnancy", "Positive");
    allPatients[1].addTestResult("Liver Function", "Fail");
    allPatients[2].addTestResult("TB", "N/A");
    allPatients[2].addTestResult("Pregnancy", "Negative");
    allPatients[2].addTestResult("Liver Function", "Pass");

    const [patientDataDisplay, setPatientDataDisplay] = useState("");
    const [activePatient, setActivePatient] = useState(new Patient(0, ""));
    const [activeDrug, setActiveDrug] = useState("");
    const [cleared, toggleCleared] = useState(false);

    const drugOptions = [
        { value: "", label: "Select", isDisabled: true },
        { value: "Humira", label: "Humira" },
        { value: "Accutane", label: "Accutane" },
    ];

    const dayOptionstab3 = [
        { value: "", label: "Select", isDisabled: true },
        { value: 7, label: "Day 7" },
        { value: 21, label: "Day 21" },
        { value: 50, label: "Day 50" },
    ];

    const dayOptionstab2 = [
        { value: "", label: "Select", isDisabled: true },
        { value: 0, label: "Day 0" },
        { value: 3, label: "Day 3" },
        { value: 7, label: "Day 7" },
    ];
    const [activeDay, setActiveDay] = useState(0);

    const [drugDisplay, setDrugDisplay] = useState("");

    const [contactMethodColSize, setContactMethodColSize] = useState(12);

    const changePatientDisplay = (event) => {
        var currentPatient;
        for (var i = 0; i < allPatients.length; i++) {
            if (allPatients[i].getName() === event.value) {
                setActivePatient(allPatients[i]);
                currentPatient = allPatients[i];
            }
        }
        setPatientDataDisplay(activePatient.toString());
        updateDrugDisplay(currentPatient, activeDrug);
    }

    const changeDrugDisplay = (event) => {
        setActiveDrug(event.value);
        updateDrugDisplay(activePatient, event.value);
    }

    const updateDrugDisplay = (patient, drug) => {
        if (drug === "Humira") {
            if (patient.getTestResults()["TB"] === "Negative") {
                setDrugDisplay("Cleared to take Humira");
                toggleCleared(true);
            }
            else {
                setDrugDisplay("Not cleared to take Humira -- must have negative TB test");
                toggleCleared(false);
            }
        }
        else if (drug === "Accutane") {
            if (patient.getTestResults()["Liver Function"] === "Pass" && patient.getTestResults()["Pregnancy"] === "Negative") {
                setDrugDisplay("Cleared to take Accutane");
                toggleCleared(true);
            }
            else {
                setDrugDisplay("Not cleared to take Accutane -- must have negative pregnancy test and pass liver function test");
                toggleCleared(false);
            }
        }
        else {
            setDrugDisplay("");
        }
    }

    const [tab1Completed, toggletab1Completed] = useState(false);
    const [tab2Completed, toggletab2Completed] = useState(false);
    const [tab3Completed, toggletab3Completed] = useState(false);
    const [tab4Completed, toggletab4Completed] = useState(false);


    return (
        <>
            <MultiDropdownNavbar />
            <a id="top" />
            <LandingPageHeader />
            <a id="product" />
            <div className="wrapper">
                <div className="section landing-section">
                    <Container >
                    </Container>
                    <Row style={{ height: "10vh" }}></Row>
                </div>
            </div>
            <div className="wrapper section section-sections section-white">
                <Container fluid>
                    <img
                        alt="..."
                        src={require("assets/img/ayush/agrawal-goat.png")}
                    />
                    <div className="section-cols">
                        <Container fluid>
                            <Row>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/3.jpeg")}
                                    />
                                </Col>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/10.jpeg")}
                                    />
                                </Col>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/4.jpeg")}
                                    />
                                </Col>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/11.jpeg")}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/5.jpeg")}
                                    />
                                </Col>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/6.jpeg")}
                                    />
                                </Col>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/1.png")}
                                    />
                                </Col>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/2.jpeg")}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/9.jpeg")}
                                    />
                                </Col>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/14.jpeg")}
                                    />
                                </Col>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/croissant-in-hand.png")}
                                    />
                                </Col>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/15.jpeg")}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/8.jpeg")}
                                    />
                                </Col>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/13.jpeg")}
                                    />
                                </Col>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/7.jpeg")}
                                    />
                                </Col>
                                <Col md="3">
                                    <img
                                        alt="..."
                                        src={require("assets/img/ayush/12.jpeg")}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container >
            </div >
            <FooterWhite />
        </>
    );
}

export default Homepage;
