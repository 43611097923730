/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer footer-white">
        <Container>
          <Row>
            <div className="credits ml-auto">
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
