import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";
import FooterGray from "components/Footers/FooterGray.js";

function BlogPosts() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
    };
  });
  return (
    <>
      <DangerNavbar />
      <div className="wrapper">
        <div className="main">
          <div className="section section-white">
            <h2 className="text-center"><b>Drug Delivery Tracking Report/Metrics</b></h2>

            <h3 className="text-center title-uppercase">
              <small>Independent specialty pharmacies can track patient outcomes throughout the delivery process</small>
            </h3>
          <br /><hr /><br />
          <iframe width="100%" height="3000vh" scrolling="no" src="https://app.smartsheet.com/b/publish?EQBCT=44ebbfd8af1d4dd3b2d8aa5909bb85c6">

          </iframe>

      </div>
      </div>
      </div>  
      <FooterGray />
    </>
  );
}

export default BlogPosts;
