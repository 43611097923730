import React from "react";
import { Link } from "react-scroll";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  const opacity = "0.5"; //0 is no opacity, 1 is transparent

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage: "linear-gradient(rgba(0, 0, 0, " + opacity + "), rgba(0, 0, 0, " + opacity + ")), " +
            "url(" + require("assets/img/aaryansinghal.jpg") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="title" style={{ fontWeight: 600 }}>
                <div style={{ color: "#51bcda", display: "inline-block" }}>hello, i'm <br />ayush GAYwala!</div>
              </h1>
              <h4 className="description">
                I see that you've stumbled upon my virtual abode on your journey through the World Wide Web. Feel free to rest and eat an ALMOND CROISSANT for a bit before you resume.
              </h4>
              <br />
              <Link activeClass="title" to="product" spy={true} smooth={true} duration={1000} offset={50}>
                <Button
                  className="btn-round"
                  color="neutral"
                  type="button"
                  outline
                >
                  Learn more about me like right now
                  <i className="fa fa-arrow-down" />
                </Button>
              </Link>
            </div>
          </Container>
        </div>
      </div >
    </>
  );
}

export default LandingPageHeader;
